export function  momentDE(){
    var moment = require("moment");

    moment.defineLocale('de', {
        months: 'Januar_Februar_März_April_Mai_Juni_Juli_August_September_Oktober_November_Dezember'.split(
            '_'
        ),
        monthsShort:
            'Jan._Feb._März_Apr._Mai_Juni_Juli_Aug._Sep._Okt._Nov._Dez.'.split('_'),
        monthsParseExact: true,
        weekdays:
            'Sonntag_Montag_Dienstag_Mittwoch_Donnerstag_Freitag_Samstag'.split(
                '_'
            ),
        weekdaysShort: 'So._Mo._Di._Mi._Do._Fr._Sa.'.split('_'),
        weekdaysMin: 'So_Mo_Di_Mi_Do_Fr_Sa'.split('_'),
        weekdaysParseExact: true,
        longDateFormat: {
            LT: 'HH:mm',
            LTS: 'HH:mm:ss',
            L: 'DD.MM.YYYY',
            LL: 'D. MMMM YYYY',
            LLL: 'D. MMMM YYYY HH:mm',
            LLLL: 'dddd, D. MMMM YYYY HH:mm',
        },
        calendar: {
            sameDay: '[heute um] LT [Uhr]',
            sameElse: 'L',
            nextDay: '[morgen um] LT [Uhr]',
            nextWeek: 'dddd [um] LT [Uhr]',
            lastDay: '[gestern um] LT [Uhr]',
            lastWeek: '[letzten] dddd [um] LT [Uhr]',
        },
        dayOfMonthOrdinalParse: /\d{1,2}\./,
        ordinal: '%d.',
        week: {
            dow: 1, // Monday is the first day of the week.
            doy: 4, // The week that contains Jan 4th is the first week of the year.
        },
      });
    return moment;
}
export  function getLocalePath(locale){
    return locale==='EN'?'en':''
}

export function containsLinks(text){

const regex = new RegExp('(HTTPS?://[^s]+)');

    return regex.test(text.toUpperCase());
    
}

export function looksLikeSpam(text){
    
const regex = new RegExp('FOLLOWER|\\bSEO\\b|\\bADS\\b|ADVERTISING|\\bFORMS\\b|\\bAI\\b|\\bFORM\\b|TRAFFIC|\\bLEADS\\b|VERIFIED|INSTAGRAM|GRITS|GROWTH|CREASE|DIRECTORIES|ROAME|BLAST|LEADS');
return regex.test(text.toUpperCase());

}

export function dateIntervalString(start_date, end_date){
    var result = ''
    const start = new Date(start_date.substring(0,10))
    const end   = new Date(end_date.substring(0,10))
  
    if (start.toString() === end.toString() ){
        result = end_date.split('|')[1] 
                 + ' '   + end_date.split('|')[3]
        
    }else if ( start.getMonth() === end.getMonth() ){
       result = start_date.split('|')[2] 
                 + ' - ' + end_date.split('|')[1] 
                 + ' '   + end_date.split('|')[3]
                 
      }
    else {
     result = start_date.split('|')[1] 
                 + ' - ' + end_date.split('|')[1] 
                 + ' '   + end_date.split('|')[3]
    }
    return result
  }

